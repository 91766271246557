<template>
  <b-modal
    id="calendar-schedule-loop-modal"
    title="Tùy chỉnh lặp lại"
    centered
    hide-footer
    @show="handleSetData"
    @hide="handleResetForm"
  >
    <form @submit.prevent="handleValidate">
      <label>Lặp lại mỗi</label>
      <div class="d-flex">
        <!-- Lặp lại mỗi -->
        <basic-select
          v-model="form.repeatTime"
          v-validate="{
            required: true,
          }"
          :options="numOptions"
          :state="validateState('repeatTime')"
          :invalidFeedback="errors.first('repeatTime')"
          required
          name="repeatTime"
          data-vv-as="Lặp lại mỗi"
          class="calendar-schedule-loop-modal__dropdown mr-2"
          value-label="text"
          track-by="value"
        />

        <!-- Khoảng thời gian lập lại -->
        <basic-select
          v-model="form.repeatType"
          v-validate="{
            required: true,
          }"
          :options="timeOptions"
          :state="validateState('repeatType')"
          :invalidFeedback="errors.first('repeatType')"
          required
          name="repeatType"
          data-vv-as="Khoảng thời gian lặp lại"
          class="calendar-schedule-loop-modal__dropdown"
          value-label="text"
          track-by="value"
          @input="onSelectRepeatTime"
        />
      </div>

      <div v-if="isShowDaysInWeek">
        <label>Lặp lại vào ngày</label>
        <div class="d-flex">
          <v-chip-group
            v-model="form.daysInWeek"
            v-validate="{
              required: true,
            }"
            required
            name="daysInWeek"
            data-vv-as="Setting lặp lại theo tuần"
            multiple
            class="calendar-schedule-loop-modal__chip-group"
          >
            <v-chip v-for="(options, index) in weekdayOptions" :key="index">
              {{ options.text }}
            </v-chip>
          </v-chip-group>
        </div>
        <div class="invalid-feedback d-block mb-4">
          {{ errors.first('daysInWeek') }}
        </div>
      </div>

      <div v-if="isShowDayInMonth" class="mb-5">
        Vào ngày {{ getDayOfDate(apptDate) }}
      </div>

      <b-row>
        <b-col class="col-option">
          <basic-radio
            v-validate="{
              required: true,
            }"
            :value.sync="form.isEnd"
            :options="endOptions"
            :state="validateState('isEnd')"
            :invalidFeedback="errors.first('isEnd')"
            required
            name="isEnd"
            data-vv-as="Kết thúc"
            label="Kết thúc"
          />
        </b-col>
        <b-col class="col-date">
          <basic-date-picker
            v-validate="{
              required: true,
            }"
            :value.sync="form.endDate"
            :state="validateState('endDate')"
            :invalidFeedback="errors.first('endDate')"
            :format="dateFomartion"
            :disabled="!isEnd"
            :disabled-dates="nowDate"
            required
            name="endDate"
            data-vv-as="Ngày kết thúc"
            placeholder=""
          />
        </b-col>
      </b-row>

      <!-- Actions -->
      <div class="d-flex justify-content-end align-items-center">
        <b-button class="btn mr-2" type="button" @click="onClickCancelButton">
          Hủy
        </b-button>
        <b-button class="btn btn-success ml-3" type="submit">
          <span class="svg-icon">
            <inline-svg src="/media/svg/icons/Neolex/Basic/save.svg" />
          </span>
          Lưu
        </b-button>
      </div>
    </form>
  </b-modal>
</template>

<script>
import { calenderMixin } from './mixins/CalenderLoop';
import { MODAL_TYPE } from '@/core/plugins/constants.js';

export default {
  name: 'CalendarScheduleLoop',

  mixins: [calenderMixin],

  props: {
    typeForm: {
      type: String,
      required: true,
    },
    apptDate: {
      type: String,
    },
  },

  data() {
    return {
      form: {
        repeatTime: '',
        repeatType: '',
        daysInWeek: null,
        isEnd: '',
        endDate: '',
      },
    };
  },

  computed: {
    isShowDaysInWeek() {
      // Show if repeat type is weekly
      if (this.form.repeatType instanceof Object) {
        return this.form.repeatType.value === 1;
      }
      return this.form.repeatType === 1;
    },

    isShowDayInMonth() {
      // Show if repeat type is monthly
      if (this.form.repeatType instanceof Object) {
        return this.form.repeatType.value === 2;
      }
      return this.form.repeatType === 2;
    },

    isEnd() {
      return this.form.isEnd === 1;
    },
  },

  methods: {
    setDataCreate() {
      this.form.repeatTime = { ...this.numOptions[0] };
      this.form.repeatType = { ...this.timeOptions[0] };
    },

    setDataUpdate() {
      if (!this.data || !Object.keys(this.data).length) return;

      const dataForm = { ...this.form };
      dataForm.repeatTime = Number(this.data.repeatTime);
      dataForm.repeatType = Number(this.data.repeatType);
      if (dataForm.repeatType === 1) {
        dataForm.daysInWeek = this.data.calendarSchedulerWeeks.map((item) =>
          Number(item.dayInWeek),
        );
      }
      dataForm.isEnd = !this.data.isRepeatInfinite;
      if (dataForm.isEnd) {
        dataForm.endDate = this.formatDate(this.data.endDate * 1000);
      }
    },

    handleValidate() {
      this.$validator.validateAll().then((result) => {
        if (result) this.handleSubmit();
      });
    },

    handleSubmit() {
      const data = { ...this.form };
      if (data.repeatTime instanceof Object) {
        data.repeatTime = data.repeatTime.value;
      }
      if (data.repeatType instanceof Object) {
        data.repeatType = data.repeatType.value;
      }
      this.$emit('save', data);

      this.$bvModal.hide('calendar-schedule-loop-modal');
    },

    onSelectRepeatTime() {
      this.daysInWeek = null;
    },

    onClickCancelButton() {
      this.$emit('cancel');
      this.$bvModal.hide('calendar-schedule-loop-modal');
    },

    handleSetData() {
      this.typeForm === MODAL_TYPE.EDIT
        ? this.setDataUpdate()
        : this.setDataCreate();
    },

    handleResetForm() {
      this.form = {
        repeatTime: '',
        repeatType: '',
        daysInWeek: null,
        isEnd: '',
        endDate: '',
      };
    },

    validateState(ref) {
      if (
        this.veeFields[ref] &&
        (this.veeFields[ref].dirty || this.veeFields[ref].validated)
      ) {
        return !this.errors.has(ref);
      }
      return null;
    },
  },
};
</script>

<style lang="scss" scoped>
.calendar-schedule-loop-modal__dropdown {
  width: 96px;
}

.calendar-schedule-loop-modal__chip-group {
  .v-chip {
    border-radius: 50%;
    height: 40px;
    font-size: 13px;
  }

  .v-chip--active {
    background-color: #b1dddb;
    color: #008479;
  }
}

.col-option {
  padding-bottom: 20px;
}

.col-date {
  display: flex;
  align-items: flex-end;
}
</style>
